import { Header } from "./header";
import { Footer } from "./footer";
import SEO from "../components/seo";

/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const Layout = ({
  location,
  children,
  pageTitle,
  hideFooter,
  hideLinks,
  navLinksOverride,
  hideSubscribe,
  buttonOverride,
  buttonOverrideClickHandler,
  hideNonCompanyLinks,
  staticHeaderLink,
  variant,
  noIndex,
}) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;

  useEffect(() => {
    console.log(
      "Hello, curious. Want to help families navigate life after death? Email us at curious@afterword.com"
    );
  }, []);

  return (
    <div
      id="global-wrapper"
      className="global-wrapper"
      data-is-root-path={isRootPath}
    >
      <Header
        location={location}
        hideLinks={hideLinks}
        navLinksOverride={navLinksOverride}
        buttonOverride={buttonOverride}
        variant={variant}
        staticHeaderLink={staticHeaderLink}
        buttonOverrideClickHandler={buttonOverrideClickHandler}
      />
      <SEO title={pageTitle} location={location} />
      {noIndex && (
        <Helmet>
          <meta name="robots" content="noindex" />
          <meta name="googlebot" content="noindex" />
        </Helmet>
      )}
      <main>{children}</main>
      {!hideFooter && (
        <Footer
          hideSubscribe={hideSubscribe}
          hideNonCompanyLinks={hideNonCompanyLinks}
        />
      )}
    </div>
  );
};

export default Layout;
