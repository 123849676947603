export const Events = {
  ACTION: {
    BUTTON_CLICK: "buttonClick",
    FORM_SUBMIT: "formSubmit",
    EXT_CLICK: "extClick",
  },
  CATEGORY: {
    CTA: "cta",
    SOCIAL: "social",
    RESOURCE: "resource",
    ZOOM: "zoom",
  },
};

export const trackEvent = (action, category, label, value) => {
  if (window.gtag) {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  } else {
    console.log(`${action} ${category} ${label} ${value}`);
  }

  window.uetq = window.uetq || [];
  if (window.uetq) {
    window.uetq.push("event", action, {
      event_category: category,
      event_label: label,
      event_value: value,
    });
  }
};

export const trackButtonClick = (category, label) => {
  trackEvent(Events.ACTION.BUTTON_CLICK, category, label);
};

export const trackButtonCta = (label) => {
  trackButtonClick(Events.CATEGORY.CTA, label);
};
