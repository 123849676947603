// Constants for routes & paths

export const Routes = {
  HOME: "/",
  ADMIN: "/admin",
  APP_STORIES: "/app/stories",
  STORIES_PREFIX: "/stories",
  JOIN_PREFIX: "/join",
  LOGIN: "/login",
  VIRTUAL_FUNERALS: "/virtual-funerals",
  INTERACTIVE_LIVESTREAMS: "/interactive-livestreams",
  SELF_GUIDED_MEMORIALS: "/self-guided-memorials",
  SERVICES: "/services",
  SERVICES_PRICING: "/services?showPricing=1",
  RESEARCH: "/research",
  BLOG: "/blog",
  TERMS: "/terms",
  PRIVACY: "/privacy",
  SECURITY: "/security",
  ABOUT: "/about",
  FAQS: "/faqs",
  CAREERS: "/careers",
  CAREERS_NOTION:
    "https://www.notion.so/Careers-Afterword-f0569315b3ea45908c78d2dc0a06778b",
  CONTACT: "/contact",
  JOINING_ZOOM: "/joining-zoom",
  ARBOR_DIRECTORS: "/arbor-directors",
  PARTNERS_FUNERAL_HOMES: "/partners/funeral-homes",
  CANADA_FUNERAL_HOMES: "/canada",

  ONLINE_PLANNER: "/online-planner",
  HERE_FOR_YOU: "/here-for-you",
  FUNERAL_PLANNING: "/funeral-planning",
  FUNERAL_HOMES: "/platform",
  FUNERAL_CONTACT_US: "/platform#contactus",
  CASE_STUDIES: "/platform#case-studies",
  PRICING: "/pricing",
  FUNERAL_FAQS: "/platform#faqs",
  FREE_BEREAVEMENT_GUIDES: "/free-bereavement-guides",
};

export default Routes;
