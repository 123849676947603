import React from "react";
import { Link } from "theme-ui";
import {
  GrFacebookOption,
  GrInstagram,
  GrTwitter,
  GrMail,
} from "react-icons/gr";
import { trackEvent, Events } from "./analytics";

const trackIconClick = (iconType) => {
  trackEvent(Events.ACTION.EXT_CLICK, Events.CATEGORY.SOCIAL, iconType);
};

export class SocialIcons extends React.Component {
  render() {
    return (
      <>
        <Link
          onClick={() => {
            trackIconClick("instagram");
          }}
          target="_blank"
          rel="noopener"
          sx={{ color: "darkBlue" }}
          href="https://instagram.com/afterword.co"
        >
          <GrInstagram className="social-icons" />
        </Link>
        <Link
          onClick={() => {
            trackIconClick("facebook");
          }}
          target="_blank"
          rel="noopener"
          sx={{ color: "darkBlue" }}
          href="https://facebook.com/theafterwordco"
        >
          <GrFacebookOption className="social-icons" />
        </Link>
        <Link
          onClick={() => {
            trackIconClick("twitter");
          }}
          target="_blank"
          rel="noopener"
          sx={{ color: "darkBlue" }}
          href="https://twitter.com/afterwordco_"
        >
          <GrTwitter className="social-icons" />
        </Link>
        <Link
          onClick={() => {
            trackIconClick("email");
          }}
          target="_blank"
          rel="noopener"
          sx={{ color: "darkBlue" }}
          href="mailto:contact@afterword.com"
        >
          <GrMail className="social-icons" />
        </Link>
      </>
    );
  }
}
