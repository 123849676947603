import React from "react";
import jsonp from "jsonp";
import PropTypes from "prop-types";

import { Button } from "theme-ui";
/** @jsx jsx */
import { jsx } from "theme-ui";

/**
 * Based on https://github.com/gndx/react-mailchimp-form/blob/master/src/index.js
 */
class Mailchimp extends React.Component {
  state = {};

  handleSubmit(evt) {
    evt.preventDefault();
    const { fields, action } = this.props;
    const values = fields
      .map((field) => {
        return `${field.name}=${encodeURIComponent(this.state[field.name])}`;
      })
      .join("&");
    const path = `${action}&${values}`;
    const url = path.replace("/post?", "/post-json?");
    const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/; // eslint-disable-line no-useless-escape
    const email = this.state["EMAIL"];
    !regex.test(email)
      ? this.setState({ status: "empty" })
      : this.sendData(url);
  }

  sendData(url) {
    this.setState({ status: "sending" });
    jsonp(url, { param: "c" }, (err, data) => {
      if (data.msg.includes("already subscribed")) {
        this.setState({ status: "duplicate" });
      } else if (err) {
        this.setState({ status: "error" });
      } else if (data.result !== "success") {
        this.setState({ status: "error" });
      } else {
        this.setState({ status: "success" });
      }
    });
  }

  render() {
    const { fields, className } = this.props;
    const messages = {
      ...Mailchimp.defaultProps.messages,
      ...this.props.messages,
    };
    const { status } = this.state;
    return (
      <form
        id="mc-subscribe"
        name="mc-subscribe"
        onSubmit={this.handleSubmit.bind(this)}
        className={className}
      >
        {fields.map((input) => (
          <input
            sx={{
              backgroundColor: "transparent",
              border: "none",
              borderBottom: "1px solid white",
              color: "white",
              height: "38px",
              marginRight: "10px",
              maxWidth: "180px",
              "&:focus": {},
            }}
            className={status === "success" && "hidden"}
            {...input}
            key={input.name}
            onChange={({ target }) =>
              this.setState({ [input.name]: target.value })
            }
            defaultValue={this.state[input.name]}
          />
        ))}
        <Button
          variant="secondary"
          className={status === "success" && "hidden"}
          disabled={status === "sending" || status === "success"}
        >
          Subscribe
        </Button>
        <div className="msg-alert">
          {status === "sending" && <p>{messages.sending}</p>}
          {status === "success" && <p>{messages.success}</p>}
          {status === "duplicate" && <p>{messages.duplicate}</p>}
          {status === "empty" && <p>{messages.empty}</p>}
          {status === "error" && <p>{messages.error}</p>}
        </div>
      </form>
    );
  }
}

Mailchimp.defaultProps = {
  messages: {
    sending: "",
    success: "Thank you for subscribing",
    error: "Uh-oh. Can you try again?",
    empty: "Please enter your email",
    duplicate: "Thanks! You're already subscribed.",
    button: "Subscribe",
  },
};

Mailchimp.propTypes = {
  action: PropTypes.string,
  messages: PropTypes.object,
  fields: PropTypes.array,
  styles: PropTypes.object,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
};

export default Mailchimp;
