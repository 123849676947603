import scrollTo from "gatsby-plugin-smoothscroll";

export const scrollToElm = (elmId, opt_timeoutOverride) => {
  setTimeout(() => {
    scrollTo(`#${elmId}`);
  }, opt_timeoutOverride ?? 100);
};

export const getWindowDimensions = () => {
  if (typeof window !== "object") {
    if (typeof document != "undefined") {
      return {
        width: document?.body.clientWidth,
        height: document?.body.clientHeight,
      };
    } else {
      return {
        width: 0,
        height: 0,
      };
    }
  }
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const getViewportWidth = () => {
  const { width } = getWindowDimensions();
  return width;
};

export const focusElm = (elmId) => {
  setTimeout(() => {
    document.getElementById(elmId).focus();
  }, 0);
};

export const checkIfHtml = (str) => /<[a-z][\s\S]*>/i.test(str);

export const IS_BROWSER = typeof window !== "undefined" && window;

export const checkIfIE = () => {
  if (IS_BROWSER) {
    let ua = window.navigator.userAgent;
    return /MSIE|Trident/.test(ua);
  }
  return false;
};
export const IS_IE = checkIfIE();
